import React from 'react';

export default () => {
    return (
        <section className="section-padding bg-gray service-page">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="col-12">
                            <div className="service-detail-sec bg-custom-white bx-wrapper">
                                <div className="service-meta mb-xl-30">
                                    <h2 className="text-custom-black fw-600">Jaký postup mám zvolit, když chci ukončit pojistnou smlouvu na vozidlo?</h2>
                                    <p className="text-light-white">Oznamte pojišťovně <strong>písemně</strong> důvod zániku smlouvy a doložte dokument, který váš důvod potvrzuje. (kupní smlouva, velký technický průkaz, protokol od policie atd.)
                                    </p> <p className="text-light-white">Nejčastější důvody ukončení pojistné smlouvy :</p>
                                    <ul>
                                        <li>Změna vlastníka vozidla</li>
                                        <li>Vyřazení vozidla z evidence</li>
                                        <li>Odcizení vozidla</li>
                                    </ul><br/>
                                    <p className="text-light-white">Pokud chcete změnit pojišťovnu, je nutné podat výpověď, a to minimálně 6 týdnů před výročním datem vaší pojistné smlouvy. Toto platí u téměř všech pojišťoven. Pouze pojišťovna Direct ukončí vaši smlouvu kdykoli.</p>
                                    <p className="text-light-white">Pokud provozujete vozidlo a ukončíte pojistnou smlouvu, vyžaduje po Vás zákon sjednání nového povinného ručení. Jízdou v nepojištěném autě se vystavujete riziku pokuty ve výši  5 000 až 40 000 Kč!</p>
                                    <h4 className="text-custom-black fw-600">Jaký způsobem mohu ukončit pojistnou smlouvu?</h4>
                                    <table
                                        className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td><strong>Moje situace</strong></td>
                                            <td><strong>Do kdy to musím <br/>stihnout?</strong></td>
                                            <td><strong>Jaké dokumenty <br/>potřebuji?</strong></td>
                                            <td><strong>Kdy mi pojištění <br/>zanikne?</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Mám výroční smlouvy.</td>
                                            <td>Do 6 týdnů před koncem pojistného období.</td>
                                            <td>–</td>
                                            <td>S koncem pojistného období.</td>
                                        </tr>
                                        <tr>
                                            <td>Nově uzavřená smlouva mi nevyhovuje.</td>
                                            <td>Do 2 měsíců od sjednání.</td>
                                            <td>–</td>
                                            <td>8 dnů od sjednání smlouvy.</td>
                                        </tr>
                                        <tr>
                                            <td>Vůz převádím na jiného vlastníka.</td>
                                            <td>–</td>
                                            <td>Kopii velkého tech. průkazu se záznamem o změně.</td>
                                            <td>V den oznámení pojišťovně.</td>
                                        </tr>
                                        <tr>
                                            <td>Vůz dočasně odhlašuji z registru.</td>
                                            <td>–</td>
                                            <td>Kopii velkého tech. průkazu se záznamem o dočasném odhlášení.</td>
                                            <td> V den oznámení pojišťovně.</td>
                                        </tr>
                                        <tr>
                                            <td>Vůz z registru odhlašuji na trvalo.</td>
                                            <td>–</td>
                                            <td>Kopii velkého tech. průkazu se záznamem o trvalém odhlášení.</td>
                                            <td> V den oznámení pojišťovně.</td>
                                        </tr>
                                        <tr>
                                            <td>Vůz mi byl ukraden.</td>
                                            <td>–</td>
                                            <td>Protokol od policie.</td>
                                            <td>V den oznámení policii.</td>
                                        </tr>
                                        <tr>
                                            <td>Vznikla mi pojistná událost.</td>
                                            <td>Do 3 měsíců od jejího ohlášení.</td>
                                            <td>–</td>
                                            <td>1 měsíc od oznámení.</td>
                                        </tr>
                                        <tr>
                                            <td>Byla mi změněna výše pojistného.</td>
                                            <td>Do 1 měsíce od doručení oznámení o změně.</td>
                                            <td>–</td>
                                            <td>Ke konci pojistného období.</td>
                                        </tr>
                                        <tr>
                                            <td>Dohodli jsme se na ukončení.</td>
                                            <td>–</td>
                                            <td>–</td>
                                            <td>K dohodnutému datu.</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
