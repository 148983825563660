import React from 'react'
import ReactPlayer from 'react-player'

export default ({url}) => {
    return (
            <div className="video-section">
                <div className="video-outer">
                    <div className="video-inner">
                        <ReactPlayer
                            url={url}
                            width="100%"
                            height="100%"
                            className="react-player"
                            config={{
                                youtube: {
                                    playerVars: {
                                        showinfo: 0,
                                        origin: 'http://localhost:3000'
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
    )
}