import React  from 'react'

export default () => {
        return (
            <section className="section-padding lw-about-section p-relative">
                <div className="side-lines right-side"> <span className="box" />
                    <span className="text">platím míň</span>
                    <span className="line" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="ln-about-left-side p-relative full-height">
                                <div className="first-img full-height">
                                    <img src="assets/images/homepage/about2.jpg" className="image-fit" alt="img" />
                                </div>
                                <div className="second-img">
                                    <img src="assets/images/homepage/about1.jpg" className="image-fit" alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="lw-about-section-right">
                                <h3 className="p-relative lw-about-right-heading">ASTORIE - služby s lehkostí motýla</h3>
                                <div className="lw-about-right-content">
                                    <p className="text-light-white">
                                        ASTORIE a.s. poskytuje pojišťovací a finanční služby. Našimi klienty jsou jak jednotlivci, rodiny, města, obce či bytové domy, tak i menší podnikatelé, velké společnosti a průmyslové firmy. Jsme ryze česká společnost a na finančním trhu působíme více než dvacet let. Provozujeme 60 kanceláří po celé České republice. Spolupracujeme se špičkovými finančními institucemi a podílíme se i na tvorbě jejich produktů. To nám umožňuje nabízet velmi široké spektrum finančních služeb, přesně podle vašich požadavků a potřeb.
                                    </p>
                                    <p className="text-light-white">
                                        Zákazník u nás stojí vždy na prvním místě. A to byl také důvod, proč vznikl tento web "platím míň". Chceme svým stávajícím i novým klientům nabízet co nejkvalitnější servis a možnost, sjednat si kvalitní pojištění za dobrou cenu v klidu z pohodlí domova. Finanční služby si lze sjednat on-line nebo se nechat kontaktovat naším profesionálním poradcem, který vám s pojištěním poradí. Záleží jen a jen na vás.
                                    </p>
                                    <p className="text-light-white">
                                        Jsme jeden z mála webů, který vám on-line srovná vaše pojištění a zároveň i umožní pojistnou smlouvu přímo sjednat. Jednoduše tady a teď.
                                    </p>
                                    <p className="text-light-white">
                                        Věříme, že budete s našimi službami spokojení.
                                    </p>
                                    <div className="lw-about-right-author">
                                        <div className="lw-about-signature">
                                            <h5>ASTORIE a.s.</h5>
                                        </div>
                                        <div className="lw-about-sign-image">
                                            <img src="assets/images/signature-home.png" alt="sign" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
}