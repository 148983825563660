import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import contacts from '../../constants/contacts'
import Navigation from "./Navigation"

import logo from '../../assets/images/logo.png'

export default (props) => {
    const [isTop, setIsTop] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setIsTop(window.scrollY > 110)
        }, false);
        if(!props.isLanding){
            window.scrollTo({
                top: 0
            });
        }
    }, [])

    function onMenuItemClick() {
        if (menuOpen) {
            setMenuOpen(false)
        }
    }

    const classNamess = isTop ? 'sticky' : '';
    return (
        <header className="header-style-1">
            {/* Start Topbar */}
            <div className="topbar-style-1">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                            <div className="left-side">
                                <p className="text-custom-white no-margin">{contacts.address}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="right-side">
                                <ul className="custom">
                                    <li><a href={contacts.fb} target="_blank" rel="noreferrer noopener"
                                           className="text-custom-white fs-14"><i className="fab fa-facebook-f"/></a>
                                    </li>
                                    <li><a href={contacts.youtube} target="_blank" rel="noreferrer noopener"
                                           className="text-custom-white fs-14"><i
                                        className="fab fa-youtube"/></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Topbar */}
            {/* Start Navigation */}
            <div className={`main-navigation-style-1 ${classNamess}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="navigation">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={logo} className="img-fluid image-fit" alt="Logo"/>
                                    </Link>
                                </div>
                                <div className={`main-menu ${menuOpen && 'active'}`}>
                                    <div className="mobile-logo">
                                        <Link to="/">
                                            <img src={logo} className="img-fluid image-fit" alt="Logo"/>
                                        </Link>
                                    </div>
                                    <Navigation isLanding={props.isLanding} onMenuItemClick={onMenuItemClick}/>
                                    <div className="right-side">
                                    </div>
                                </div>
                                <div className={`hamburger-menu ${menuOpen && 'active'}`}
                                     onClick={() => setMenuOpen(!menuOpen)}>
                                    <div className="menu-btn"><span/>
                                        <span/>
                                        <span/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Navigation */}
        </header>
    )
}