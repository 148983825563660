import React, {useState, useEffect} from 'react'
import ReactWOW from 'react-wow'
import {Modal} from "react-bootstrap"
import {useLocation} from 'react-router-dom'

const Features = () => {
    return (
        <ul>
            <li><strong>on-line</strong></li>
            <li>pojistka<br/><strong>v pohodlí z domova</strong></li>
            <li>pojistka<br/><strong>za pár minut</strong></li>
            <li>pojištění<br/><strong>z mobilu</strong></li>
            <li><strong>nonstop</strong><br/>služby</li>
        </ul>
    )
}

const intropost = [
    {
        title: "POJIŠTĚNÍ VOZIDEL",
        identifier: "auto",
        para: "Srovnejte pojistky a vyberte si tu nejvýhodnější. TADY a TEĎ.",
        urltext: "Zjistit více",
        url: "https://platimmin.sjednavac-astorieas.cz/auto/",
        target: "modal",
        addcls: "intro-box bg-dark-brown mb-md-20",
        animationname: "fadeInUp",
        animationdur: "1s",
        animationdelay: "0.3s",
        modalText: <>
            <p>Srovnejte pojistky a vyberte si tu nejvýhodnější <strong>tady</strong> a <strong>teď</strong></p>
            <h2>Za <strong>POJIŠTĚNÍ VOZIDEL</strong> platím míň.</h2>
            <p>Sjednejte si výhodně kvalitní pojistku</p>
            <p>
                Vybrat si pojišťovnu, která vám nabídne <strong>nejvýhodnější povinné ručení</strong> nebo <strong>havarijní
                pojištění</strong> bývá často velký problém. Nabídky mají mnoho parametrů, jsou velmi nepřehledné a
                často před vámi i mnohé skrývají. V pojišťovací samoobsluze "platím míň" získáte nabídky NAROVINU.
                Jednoduše tu nejvýhodnější. Zaleží jen a jen na vás.
            </p>
            <p>Vyberte se kvalitní a nejvýhodnější pojistku, která myslí na všechny nástrahy. Současně nabízí i pomocnou
                ruku na silnici v podobě <strong>asistenčních služeb</strong>, rychlého <strong>řešení vzniklých
                    škod</strong> a vstřícného jednání.</p>
            <br/>
            <p>Smlouva je uzavřena zaplacením pojistného.</p>
            <Features/>
        </>
    },
    {
        title: "CESTOVNÍ POJIŠTĚNÍ",
        identifier: "cesta",
        para: "Přibalte si pro klid do kufru kvalitní pojistku! TADY a TEĎ.",
        urltext: "Zjistit více",
        url: "https://www.koop.cz/cestovni-pojisteni?kodzi=400013",
        addcls: "intro-box bg-dark-brown mb-md-20",
        animationname: "fadeInUp",
        animationdur: "1.5s",
        animationdelay: "0.6s",
        modalText: <>
            <p>Přibalte si pro klid do kufru kvalitní pojistku <strong>tady</strong> a <strong>teď</strong></p>
            <h2>Za <strong>CESTOVNÍ POJIŠTĚNÍ</strong> platím míň</h2>
            <p>Kvalitní pojistka na cestách vám přinese pohodu</p>
            <p>
                Ano. Výhodná pojistka od Kooperativy vám zajistí bezstarostné cestování. Uhradí vám nejen <strong>léčebné
                výlohy</strong> nebo <strong>zásah záchranné či horské služby</strong>, ale i pomoc při zpožděném či
                zrušeném letu. Myslí na vás i při odcizení zavazadel, poškození sportovního vybavení nebo když budete
                mít trable se zapůjčeným vozem. Zaleží jen a jen na vás jak si pojistku nastavíte. A navíc, k dospělému
                cestujícímu máte pojistku dítěte do šesti let již v ceně.
            </p>
            <p>Kvalitní pojistkou od Kooperativy můžete pojistit jednotlivce i skupiny. Při častém cestování
                získáte <strong>slevu až 40 %</strong>.</p>
            <br/>
            <p>Smlouva je uzavřena zaplacením pojistného.</p>
            <Features/>
        </>
    },
    {
        title: "ÚRAZOVÉ POJIŠTĚNÍ",
        identifier: "uraz",
        para: "Vězte, že neštěstí nechodí po horách, ale po lidech. Sjednejte si pojistku TADY a TEĎ.",
        urltext: "Zjistit více",
        url: "https://www.flexi.cz/qf/cs/ramjet/iframe/flexi-risk/pruvodce?partnerId=96063",
        addcls: "intro-box bg-dark-brown mb-xs-20",
        animationname: "fadeInUp",
        animationdur: "2s",
        animationdelay: "1s",
        modalText: <>
            <p>Vězte, že neštěstí nechodí po horách, ale po lidech</p>
            <p>Sjednejte si pojistku <strong>tady</strong> a <strong>teď</strong></p>
            <h2>Za <strong>ÚRAZOVÉ POJIŠTĚNÍ</strong> platím míň.</h2>
            <p>Kvalitní pojistka vás nenechá nikdy na holičkách</p>
            <p>
                Úrazové pojištění FLEXI RISK od Kooperativy vám vždy kryje záda, a to při veškerých vašich aktivitách.
                Je maximálně férové, protože je vytvořeno na základě vašich <strong>osobních údajů, životního
                stylu</strong> a <strong>vašich aktivit</strong>. Ano. FLEXI RISK myslí i na milovníky adrenalinových
                sportů. Záleží jen a jen na vás. Pojistnou událost můžete nahlásit přes web a výplatu za léčení úrazu
                obdržíte obratem po doložení lékařské zprávy z prvního ošetření.
            </p>
            <p>S kvalitní pojistkou od Kooperativy si můžete užívat život naplno. Při sjednání úrazového pojištění
                získáte cestovní pojištění pro jednu osobu zdarma.</p>
            <br/>
            <p>Smlouva je uzavřena zaplacením pojistného.</p>
            <Features/>
        </>
    },
    {
        title: "POJIŠTĚNÍ MAJETKU",
        identifier: "majetek",
        para: "Chraňte svůj domov a majetek. TADY a TEĎ.",
        urltext: "Zjistit více",
        url: "https://insure.koop.cz/Nemovitost/?kodzi=400013",
        addcls: "intro-box bg-dark-brown",
        animationname: "fadeInUp",
        animationdur: '2.5s',
        animationdelay: '1.3s',
        modalText: <>
            <p>Chraňte svůj domov a majetek <strong>tady</strong> a <strong>teď</strong></p>
            <h2>Za <strong>POJIŠTĚNÍ MAJETKU</strong> platím míň.</h2>
            <p>Můj dům, můj hrad</p>
            <p>
                Ano. Kvalitní pojistka je účinnější nežli ty nejdolnější hradby. Spolehlivě ochrání váš byt, rodinný
                dům, chalupu, ale i veškeré vybavení a příslušenství. Vztahuje se nejen na <strong>vloupání a přírodní
                živly</strong>, ale třeba i na poškození zateplení stěn domu zvířetem. Myslí i na vaše <strong>vedlejší
                objekty</strong>, včetně stavebního materiálu. Pojistit si můžete i chalupu ve výstavbě či v
                rekonstrukci. A navíc, kvalitní pojistka myslí také na vaše koníčky. Pojistit lze i vašeho koně. Jak si
                pojistku nastavíte, záleží jen a jen na vás.
            </p>
            <p>S kvalitní pojistkou od Kooperativy můžete být v klidu, ať jste kdekoli. Minimalizuje totiž vaše rizika
                na minimum. Při sjednání pojištění získáte také cestovní pojištění pro jednu osobu zdarma.</p>
            <br/>
            <p>Smlouva je uzavřena zaplacením pojistného.</p>
            <Features/>
        </>
    },
]

export default () => {
    const [modal, setModal] = useState(false)
    const [conditionAccepted, setConditionAccepted] = useState(false)
    const location = useLocation()

    useEffect(() => {
        if (modal) {
            try {
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-401922554/w9gvCISk6f0BEPqz078B',
                    'value': 1.0,
                    'currency': 'CZK',
                    'event_callback': null
                });
            } catch (e) {

            }
        } else {
            setConditionAccepted(false)
        }
    }, [modal])

    useEffect(() => {
        if (location.search) {
            const sp = new URLSearchParams(location.search)
            const insuranceType = sp.get('tp')
            if (insuranceType) {
                const modalItem = intropost.find(i => i.identifier === insuranceType)
                if (modalItem) {
                    setModal(modalItem)
                }
            }
        }
    }, [location])

    return (
        <section className="genmed-intro" id="pojisteni">
            <div className="container">
                <div className="row justify-content-center">
                    {intropost.map((item, i) => (
                        <div key={i} className="col-lg-3 col-sm-6 mb-4">
                            <ReactWOW animation={item.animationname} data-wow-duration={item.animationdur}
                                      data-wow-delay={item.animationdelay}>
                                <div className={item.addcls}>
                                    <div className="intro-wrapper text-center">
                                        <h6 className="text-custom-white fw-700">{item.title}</h6>
                                        <p className="text-custom-white">{item.para}</p>
                                        <a href="#" className="btn-first btn-submit fw-600" onClick={(e) => {
                                            e.preventDefault();
                                            setModal(item)
                                        }}>{item.urltext}</a>
                                    </div>
                                </div>
                            </ReactWOW>
                        </div>
                    ))}
                </div>
            </div>
            <Modal show={modal !== false} onHide={() => setModal(false)}
                   className={`form-modal ${conditionAccepted ? '' : 'smaller'}`} size='xl'>
                <Modal.Body>
                    <div className="hamburger-menu active"
                         onClick={() => setModal(false)}>
                        <div className="menu-btn"><span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <div className="heading">
                        <h2>{modal.title}</h2>
                    </div>
                    {
                        conditionAccepted ? (
                            <iframe src={modal.url} title="pojisteni vozidel"/>
                        ) : (
                            <div className="modal-accept-text">
                                <div className="position-relative">
                                    <p className="main-p">
                                        {modal.modalText}
                                    </p>
                                    <div className="text-center">
                                        {
                                            modal.target == 'modal' ? (
                                                <button type="button" className="btn-first btn-submit fw-600"
                                                        onClick={() => setConditionAccepted(true)}>Pokračovat</button>
                                            ) : (
                                                <a href={modal.url} target="_blank" rel="noopener noreferrer"
                                                   className="btn-first btn-submit fw-600">Pokračovat</a>
                                            )
                                        }
                                    </div>
                                    <div className="side-lines right-side"> <span className="box" />
                                        <span className="text">platím <span>míň.cz</span></span>
                                        <span className="line" />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
            </Modal>
        </section>

    )
}
