import React from 'react';
import Header from '../layouts/Header';
import Breadcrumb from '../layouts/Breadcrumb';
import Contactform from '../sections/contact/Contactform';
import Contactinfo from '../sections/contact/Contactinfo';
import Footer from '../layouts/Footer';

export default () => {
        return (
            <>
                <Header/>
                <Breadcrumb heading="Kontaktuje nás"/>
                <Contactform/>
                <Contactinfo/>
                <Footer/>
            </>
        )
}