import React  from 'react'
import Slider from "react-slick"

import partner1 from '../../../assets/images/partners/partner1.png';
import partner2 from '../../../assets/images/partners/partner2.png';
import partner3 from '../../../assets/images/partners/partner3.png';
import partner4 from '../../../assets/images/partners/partner4.png';
import partner5 from '../../../assets/images/partners/partner5.png';
import partner6 from '../../../assets/images/partners/partner6.png';

const partners = [
    {
        photo: partner1,
    },
    {
        photo: partner2,
    },
    {
        photo: partner3,
    },
    {
        photo: partner4,
    },
    {
        photo: partner5,
    },
    {
        photo: partner6,
    },
];
const testimonialascontent = [
    {
        title: "Servis, čas a péče",
        description: "Děkujeme za veškerý servis, čas a péči ohledně našich záležitostí v pojistných smloouvách. Ceníme si rychlého a vstřícného jednání a doporučení která jsme od Vás dostali. Splnila očekávání a jsme spokojeni.",
        name: "František a Monika Dobrovičovi",
        designation: "Česká Lípa",
    },
    {
        title: "Vyřízení úrazu",
        description: "Děkuji za veškeré služby a přístup ke klientům. Jsem velice spokojena s výsledky řešení mého úrazu a rychlé vyřízení pojistné události u Pojišťovny České spořitelny.",
        name: "Anonymní klientka",
        designation: "",
    },
    {
        title: "Půjčka",
        description: "Děkuji za pomoc s hledáním banky která mi byla ochotna poskytnout půjčku za výhodných podmínek v situaci, kdy jiné banky odmítaly.",
        name: "Anděla Zadáková",
        designation: "",
    }
]
export default () => {
    const setting2 = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        draggable: false,
        dots: false,
        vertical: true,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 4000,
    }
    return (
        <section className="section-padding findrate-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6" id="reference">
                        <div className="testimonials-box mb-md-80">
                            <div className="section-header">
                                <div className="section-heading">
                                    <h3 className="text-custom-black fw-700">Reference</h3>
                                </div>
                                <div className="section-description">
                                    <p className="text-light-white">Spokojenost našich klientů je pro nás samozřejmostí.
                                        Níže uvádíme pár přepisů vybraných emailů, které jsme obdrželi.</p>
                                </div>
                            </div>
                            <div className="testimonials-wrapper">
                                <div className="col-12">
                                    <Slider
                                        slidesToShow={1}
                                        swipeToSlide={true} className="testimonial-for row" {...setting2}>
                                        {testimonialascontent.map((item, i) => (
                                            <div key={i} className="slide-item col-12">
                                                <div className="testimonials-inner">
                                                    <div className="testimonial-content">
                                                        <h4 className="text-custom-blue fw-700 fs-20">{item.title}</h4>
                                                        <p className="text-light-white mb-xl-20">{item.description}</p>
                                                        <p className="text-custom-black fw-700"><strong>{item.name}<br/><span
                                                            className="text-light-blue fw-500">{item.designation}</span></strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6" id="partneri">
                        <div className="clients-box">
                            <div className="section-header">
                                <div className="section-heading">
                                    <h3 className="text-custom-black fw-700">Naši partneři</h3>
                                </div>
                                <div className="section-description">
                                    <p className="text-light-white">Úzce spolupracujeme v uvedenými pojišťovnami</p>
                                </div>
                            </div>
                            <div className="clients-wrapper">
                                {partners.map((item, i) => (
                                    <div key={i} className="client-box">
                                        <div className="client-item">
                                            <img src={item.photo} className="" alt="img"/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}