import React, {useState, useEffect} from "react"
import {Container} from "react-bootstrap"
import draftToHtml from "draftjs-to-html"
import Header from '../layouts/Header'
import {fetchUrl} from '../../constants/fetch'
import axios from "axios"
import { useHistory } from "react-router-dom"
import Breadcrumb from "../layouts/Breadcrumb"
import Footer from "../layouts/Footer"

export default Document = (props) => {
    const [loading, setLoading] = useState(true)
    const [document, setDocument] = useState({})
    const [error, setError] = useState(false)
    const history = useHistory()
    const identifier = props.match.params.identifier

    useEffect(() => {
        axios({
            method: 'GET',
            url: fetchUrl + 'public-data/documents/' + identifier
        }).then(res => {
            setDocument(res.data.data)
            setLoading(false)
        }).catch(err => {
            setError(true)
            setLoading(false)
        })
    }, [identifier])


    if(document == null || error){
        history.push('/404')
        return null
    }

    function replaceImgLink(value = '') {
        return value ? value.replace(/<a (.+)>(.*)(http(s?):\/\/.*\.(jpg|png))(.*)<\/a>/gim, '<a $1><img src="$3" alt="" class="d-block my-2"/></a>') : ''
    }

    function replaceSpaces(value = '') {
        return value ? value.replace(/\s{2,}/g, "&nbsp;") : ''
    }

    return (
        <div>
            <Header/>
            <Breadcrumb heading={document.name}/>
            <section className="document">
                {loading && <div className="loading"/>}
                <Container>
                    <div dangerouslySetInnerHTML={{__html: replaceImgLink(replaceSpaces(draftToHtml(document.text)))}}
                         className="wysiwyg-preview"></div>
                </Container>
            </section>
            <Footer/>
        </div>
    )
}

