import React from 'react';
import Header from '../layouts/Header';
import Banner from '../sections/home/Banner';
import Intro from '../sections/home/Intro';
import About from '../sections/home/About';
import Paymenttype from '../sections/home/Paymenttype';
import Blog from '../sections/home/Blog';
import Bookappointment from '../layouts/Bookappointment';
import Testimonials from '../sections/home/Testimonials';
import Footer from '../layouts/Footer';

export default () => {
        return (
            <>
                <Header isLanding={true}/>
                <Banner/>
                <Intro/>
                <About/>
                <Paymenttype/>
                <Blog/>
                <Bookappointment/>
                <section className="map">
                        <iframe src="https://frame.mapy.cz/s/mezovelugo"
                                frameBorder="0"/>
                </section>
                <Testimonials/>
                <Footer isLanding={true}/>
            </>
        )
}

