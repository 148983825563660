import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Slider from "react-slick"
import {Modal} from "react-bootstrap"
import Video from "../../layouts/Video"

// images
import blogimg1 from '../../../assets/images/blog/blog1.jpg'
import blogimg2 from '../../../assets/images/blog/blog2.jpg'
import blogimg3 from '../../../assets/images/blog/blog3.jpg'
import blogimg4 from '../../../assets/images/blog/blog4.jpg'
import blogimg5 from '../../../assets/images/blog/blog5.jpg'
import blogimg6 from '../../../assets/images/blog/blog6.jpg'
import blogimg7 from '../../../assets/images/blog/blog7.jpg'
import blogimg8 from '../../../assets/images/blog/blog8.jpg'

const blogpost = [
    {
        photo: blogimg2,
        title: "Když Vám štěstí nedá přednost",
        description: "Stál jsem na křižovatce a najednou přijelo neviditelné auto, narazilo do mého vozidla a zmizelo",
        url: "https://www.youtube.com/watch?v=wYnse7n56gA",
        target: "modal",
        urltext: "Přehrát video"
    },
    {
        photo: blogimg3,
        title: "Sedneme si na celý život",
        description: "Světek nehody odešel - prý už jednou seděl a na policajty čekat nebude.",
        url: "https://www.youtube.com/watch?v=s4k4ruyRHm8",
        target: "modal",
        urltext: "Přehrát video"
    },
    {
        photo: blogimg4,
        title: "Dva roky prázdnin",
        description: "Po jednom výletu na krásný útes.",
        url: "https://www.koop.cz",
        target: "_blank"
    },
    {
        photo: blogimg5,
        title: "Partner, který se na Vás nevykašle",
        description: "Nechtěné těhotenství nebylo cílem dovolené - požaduji odškodné za duševní trauma.",
        url: "https://www.youtube.com/watch?v=ZOzDh8M-guE",
        target: "modal",
        urltext: "Přehrát video"
    },
    {
        photo: blogimg6,
        title: "Umíme se vcítit",
        description: "Syrečky zapadly do sedačky a auto teď smrdí jako tchoř. Já se ježděním živím.",
        url: "https://www.youtube.com/watch?v=WXFgYDE7gbU",
        target: "modal",
        urltext: "Přehrát video"
    },
    {
        photo: blogimg7,
        title: "Letadlo nečeká",
        description: "Spoj uletěl, tak zůstal jako poslední mohykán.",
        url: "https://www.koop.cz",
        target: "_blank",
    },
    {
        photo: blogimg8,
        title: "Hoďte to na nás",
        description: "Kamarádka mi ukazovala, jak umí házet oštěpem.",
        url: "https://www.youtube.com/watch?v=q1F5Ee2OICU",
        target: "modal",
        urltext: "Přehrát video"
    },
]


export default (props) => {
    const [modal, setModal] = useState(false)

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        loop: true,
        autoplay: true,
        speed: 800,
        cssEase: 'linear',
        responsive: [{
            breakpoint: 992,
            settings: {
                arrows: true,
                slidesToShow: 2,
            }
        }, {
            breakpoint: 768,
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
            }
        }]
    };
    return (
        <section className="section-padding our_articles">
            <div className="container">
                <div className="section-header">
                    <div className="section-heading">
                        <h3 className="text-custom-black fw-700">Aktuálně</h3>
                        <div className="section-description">
                            <p className="text-light-white"></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 no-padding">
                        <Slider className="blog-slider" {...settings}>
                            {blogpost.map((item, i) => (
                                <article key={i} className="post col-12">
                                    <div className="single-blog">
                                        <div className="post-meta">
                                        </div>
                                        <div className="blog-photo">
                                            <img src={item.photo} className="full-width" alt="#"/>
                                        </div>
                                        <div className="post-contnet">
                                            <h2 className="post-title text-custom-black">{item.title}</h2>
                                            <p className="text-light-white">{item.description}</p>
                                        </div>
                                        <div className="blog-overlay">
                                            <div className="right-text-effect post-content">
                                                <h2 className="post-title">
                                                    {
                                                        item.target === '_blank' ? (
                                                            <a href={item.url} target="_blank" className="text-white"
                                                               rel="noreferrer noopener">{item.title}</a>
                                                        ) : (
                                                            item.target === 'modal' ? (
                                                                <Link to="#" className="text-white"
                                                                      onClick={() => setModal(item.url)}>{item.title}</Link>
                                                            ) : (
                                                                <Link to={item.url}
                                                                      className="text-white">{item.title}</Link>
                                                            )
                                                        )
                                                    }
                                                </h2>
                                                <p>{item.description}</p>
                                            </div>
                                            <div className="blog-read-more-icon">
                                                {
                                                    item.target === '_blank' ? (
                                                        <a href={item.url} target="_blank"
                                                           rel="noreferrer noopener">{item.urltext || 'Více informací'}</a>
                                                    ) : (
                                                        item.target === 'modal' ? (
                                                            <Link to="#" className="fw-500 fs-14"
                                                                  onClick={() => setModal(item.url)}>{item.urltext || 'Více informací'}</Link>
                                                        ) : (
                                                            <Link to={item.url}
                                                                  className="fw-500 fs-14">{item.urltext || 'Více informací'}</Link>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
            <Modal show={modal !== false} onHide={() => setModal(false)} className="video-modal" size="xl">
                <Modal.Body>
                    <div className="hamburger-menu active"
                         onClick={() => setModal(false)}>
                        <div className="menu-btn"><span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <Video url={modal}/>
                </Modal.Body>
            </Modal>
        </section>
    )
}