import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Container, Row, Col} from 'react-bootstrap'

import Navigation from "./Navigation"
import contacts from "../../constants/contacts"
import axios from "axios";
import {fetchUrl} from "../../constants/fetch";
import GDPR from "../GDPR";

export default (props) => {
    const [isTop, setIsTop] = useState(false)
    const [documents, setDocuments] = useState([])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setIsTop(window.scrollY > 300)
        }, false);

        axios({
            method: 'GET',
            url: fetchUrl + 'public-data/documents'
        }).then(res => {
            setDocuments(res.data.data)
        })
    }, [])

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const classNames = isTop ? 'active' : '';

    return (
        <>
            <div>
                <footer className="bg-black section-padding footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="footer-box mb-md-80">
                                    <div className="footer-heading">
                                        <h4 className="text-custom-white no-margin">Užitečné odkazy</h4>
                                    </div>
                                    <Navigation isLanding={props.isLanding} isFooter={true}/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="footer-box mb-md-80">
                                    <div className="footer-heading">
                                        <h4 className="text-custom-white no-margin">Kontakty</h4>
                                    </div>
                                    <div className="custom">
                                        <p className="text-custom-white">{contacts.address}</p>
                                        <p className="text-custom-white">{contacts.phone}</p>
                                        <p className="text-custom-white">{contacts.email}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 d-none d-lg-block">
                                <div className="footer-box">
                                    <div className="footer-heading">
                                        <h4 className="text-custom-white no-margin">Nepropásněte</h4>
                                    </div>
                                    <div className="social-media">
                                        <ul className="custom social-media">
                                            <li><a href={contacts.fb} target="_blank" rel="noreferrer noopener"><i className="fab fa-facebook-f"/></a>
                                            </li>
                                            <li><a href={contacts.youtube} target="_blank" rel="noreferrer noopener"><i className="fab fa-youtube"/></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <section className="footerBottomArea">
                    <Container>
                        <Row className="align-items-center">
                            <Col sm={4}>
                                <p className="text-custom-white no-margin">© <Link to="#" target="_blank">platímmíň.cz</Link> - {new Date().getFullYear()}
                                </p>
                            </Col>
                            <Col sm={8}>
                                <ul className="no-margin">
                                    {
                                        documents.map((item, index) => {
                                            return(
                                                <li key={index}><Link to={`/dokument/${item.identifier}`}>{item.name}</Link></li>
                                            )
                                        })
                                    }
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* End Footer */}
                {/* back to top */}
                <div id="back-top" className={`back-top ${classNames}`} onClick={() => scrollToTop()}><Link to="#top"><i
                    className="fa fa-arrow-up"/></Link>
                </div>
            </div>
            <GDPR gdprDocument={documents.find(d => d.identifier == "gdpr")}/>
        </>
    )
}

