import React from 'react';

const homeloan = [
    {
        title: "MINUTOVÁ PŮJČKA EQUA BANK",
        para: "Potřebujte půjčit peníze za férových podmínek? TADY a TEĎ.",
        url: "https://www.equabanking.cz/cashloans/?channelID=30109999&cid=3rdParty_Astorie_P&webEntry=link&p=1&embedded#z1s2"
    },
    {
        title: "RE PŮJČKA EQUA BANK",
        para: "Chcete zlevnit vaši půjčku? TADY a TEĎ.",
        url: "https://www.equabanking.cz/cashloans/?channelID=30109999&cid=3rdParty_Astorie_Rep&webEntry=link&p=2&embedded#z1s2"
    },
    {
        title: "KONSOLIDACE ÚVĚRŮ OD EQUA BANK",
        para: "Potřebovali byste snížit splátky na všechny vaše půjčky? TADY a TEĎ.",
        url: "https://www.equabanking.cz/cashloans/?channelID=30109999&cid=3rdParty_Astorie_Conso&webEntry=link&p=3&embedded#z1s2"
    },
];


export default () =>  {
        return (
            <section id="pujcky">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 no-padding">
                            <div className="ln-type-payment-tabs">
                                <div className="section-padding ln-type-payment">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="tab-inner">
                                                    <div className="tab-heading section-padding-bottom">
                                                        <h3 className="text-custom-white">Půjčte si výhodně peníze bez ztrácení času v bance! </h3>
                                                        <p className="text-custom-white no-margin">Spolupracujeme s bankovní společností EQUA BANK</p>
                                                    </div>
                                                    <div className="row">
                                                        {homeloan.map((item, i) => (
                                                            <div key={i} className="col-lg-4 col-md-6">
                                                                <div className="ln-type-payment-box mb-xl-30">
                                                                    <div className="content-wrap">
                                                                        <h4 className="text-custom-white fw-600">{item.title}</h4>
                                                                        <p className="text-custom-white">{item.para}</p>
                                                                        <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-custom-white fw-500 fs-14">Zjistit více</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
}