import React  from 'react';
import { Link } from 'react-router-dom';
import contacts from "../../../constants/contacts"

export default () => {
        return (
            <section className="section-padding bg-gray contact-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="contact-info bx-wrapper bg-custom-white text-center mb-md-40">
                                <div className="contact-info-wrapper">
                                    <div className="icon mb-xl-20"> <i className="fas fa-phone-alt" />
                                    </div>
                                    <h5 className="text-custom-black fw-600">Telefon</h5>
                                    <p className="text-light-white">Volejte ve všední dny mezi 8 - 15 hodinou.</p> <Link to="#" className="fs-14">{contacts.phone}</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="contact-info bx-wrapper bg-custom-white text-center mb-md-40">
                                <div className="contact-info-wrapper">
                                    <div className="icon mb-xl-20"> <i className="far fa-envelope" />
                                    </div>
                                    <h5 className="text-custom-black fw-600">Email</h5>
                                    <p className="text-light-white">Napište nám email a odpovíme hned, jak to bude možné.</p> <a href={`mailto:${contacts.email}`} className="fs-14">{contacts.email}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="contact-info bx-wrapper bg-custom-white text-center">
                                <div className="contact-info-wrapper">
                                    <div className="icon mb-xl-20"> <i className="fas fa-map-marker-alt" />
                                    </div>
                                    <h5 className="text-custom-black fw-600">Adresa</h5>
                                    <p className="text-light-white">{contacts.address}</p> <a href={contacts.map} target="_blank" rel="noreferrer noopener" className="fs-14">Zobrazit na mapě</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
}