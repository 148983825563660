import React  from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import contacts from "../../constants/contacts"

export default () => {
        return (
            <div className="extra-page">
                <div className="header">
                    <Link to="/" className="logo">
                        <img src={logo} alt="" />
                    </Link>
                    <a href={contacts.fb}> <i className="fab fa-facebook-f" />
                    </a>
                    <a href={contacts.youtube}> <i className="fab fa-youtube" />
                    </a>
                    <div className="divider" />
                    <Link to="/"> <i className="fas fa-arrow-left" />
                    </Link>
                </div>
                <div className="ghost"> <span className="text">404</span>
                    <div className="eye" />
                    <div className="eye two" />
                    <div className="mouth" />
                    <div className="corner" />
                    <div className="corner two" />
                    <div className="corner three" />
                    <div className="corner four" />
                    <div className="over" />
                    <div className="over two" />
                    <div className="over three" />
                    <div className="over four" />
                    <div className="shadow" />
                </div>
                <div className="main">
                    <div className="error">chyba</div>
                    <h2>Stránka nenalezena</h2>
                    <h6 className="text-custom-white">Požadovaná stránka byla pravděpodobně odstraněna, změněna <br/>nebo je dočasně nedostupná.</h6>
                </div>
            </div>
        )
}