import React, {useState, useEffect, useContext} from "react"
import {Accordion, Button, Row, Col, AccordionContext} from "react-bootstrap"
import {Link, useLocation} from "react-router-dom"
import Switch from "rc-switch"
import CookieIcon from './CookieIcon'
import ChevronRight from './ChevronRight'
import "rc-switch/assets/index.css"


const defaultCookieSettings = {
    is_default: true,
    cookies: {
        necessary: true,
        statistics: false,
        marketing: false
    }
}

const cookiesData = {
    necessary: {
        heading: "Funkční",
        info: "Technické uložení nebo přístup je nezbytně nutný pro legitimní účel umožnění použití konkrétní služby, kterou si odběratel nebo uživatel výslovně vyžádal, nebo pouze za účelem provedení přenosu sdělení prostřednictvím sítě elektronických komunikací."
    },
    statistics: {
        heading: "Statistické",
        info: "Technické uložení nebo přístup, který se používá výhradně pro statistické účely."
    },
    marketing: {
        heading: "Marketingové",
        info: "Technické uložení nebo přístup je nutný k vytvoření uživatelských profilů za účelem zasílání reklamy nebo sledování uživatele na webových stránkách nebo několika webových stránkách pro podobné marketingové účely."
    }
}

const cookie_settings = localStorage.getItem("__cs")
let INIT_COOKIE_SETTINGS = cookie_settings ? JSON.parse(cookie_settings) : defaultCookieSettings
if(Object.keys(INIT_COOKIE_SETTINGS.cookies).length < Object.keys(defaultCookieSettings.cookies).length){
    INIT_COOKIE_SETTINGS = defaultCookieSettings
}

const Indicator = ({eventKey}) => {
    const currentEventKey = useContext(AccordionContext)
    const isCurrentEventKey = currentEventKey === eventKey
    return <span className={isCurrentEventKey ? 'active' : ''}><ChevronRight/></span>
}

export default (props) => {
    const [cookieSettings, setCookieSettings] = useState({...INIT_COOKIE_SETTINGS})
    const isDefault = cookieSettings.is_default
    const [visible, toggleVisible] = useState(false)
    const [settingsVisible, toggleSettingsVisible] = useState(false)
    const location = useLocation()
    const isGdpr = location.pathname.includes(`gdpr-`)

    const setCookieSettings_ = (cookies) => {
        const newData = {
            cookies,
            is_default: false
        }
        localStorage.setItem("__cs", JSON.stringify(newData))
        setCookieSettings(newData)
        toggleVisible(false)
    }

    useEffect(() => {
        if (isDefault && !isGdpr) {
            setTimeout(() => {
                toggleVisible(true)
            }, 2500)
        } else {
            if (visible) {
                toggleVisible(false)
            }
        }
    }, [isDefault, isGdpr])

    useEffect(() => {
        if (!visible) {
            toggleSettingsVisible(false)
        }
    }, [visible])

    function setSettings(name) {
        const cs = {...cookieSettings}
        cs.cookies[name] = !cs.cookies[name]
        setCookieSettings(cs)
    }

    function save(type) {
        const cs = {...cookieSettings.cookies}
        if (type < 2) {
            Object.keys(cs).map(k => {
                cs[k] = k == "necessary" || type
            })
        }
        setCookieSettings_(cs)
    }

    return (
        <div className={`gdpr ${visible ? 'active' : ''}`}>
            {
                !cookieSettings.is_default &&
                <Button className="cookie-button" onClick={() => toggleVisible(!visible)}><CookieIcon/></Button>
            }
            <div className="p-4">
                <div>
                    <h5>Spravovat Souhlas s cookies</h5>
                    <p>Abychom poskytli co nejlepší služby, používáme k ukládání a/nebo přístupu k informacím o zařízení, technologie jako jsou soubory cookies. Souhlas s těmito technologiemi nám umožní zpracovávat údaje, jako je chování při procházení nebo jedinečná ID na tomto webu. Nesouhlas nebo odvolání souhlasu může nepříznivě ovlivnit určité vlastnosti a funkce.</p>
                    {
                        settingsVisible ? (
                            <div className="mt-3">
                                <Accordion as="ul">
                                    {
                                        Object.keys(cookieSettings.cookies).map(k => {
                                            return (
                                                <li eventKey={k} key={k}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6>{cookiesData[k].heading}</h6>
                                                        <div className="d-flex align-items-center justify-content-between caret-icon">
                                                            <Switch
                                                                id={k}
                                                                checked={!!cookieSettings.cookies[k]}
                                                                onChange={() => setSettings(k)}
                                                                disabled={k === 'necessary'}
                                                                className="custom-switch custom-switch-primary custom-switch-small mr-3"
                                                            />
                                                            <Accordion.Toggle eventKey={k}><Indicator eventKey={k}/></Accordion.Toggle>
                                                        </div>
                                                    </div>
                                                    <Accordion.Collapse eventKey={k}>
                                                        <p>{cookiesData[k].info}</p>
                                                    </Accordion.Collapse>
                                                </li>
                                            )
                                        })
                                    }
                                </Accordion>
                            </div>
                        ) : null
                    }
                </div>
                <div className="mt-4">
                    <Row>
                        <Col sm={4}>
                            <Button variant="outline-primary" className="mb-1 d-block" onClick={() => save(1)}>
                                Přijmout
                            </Button>
                        </Col>
                        <Col sm={4}>
                            <Button variant="outline-secondary" className="mb-1 d-block" onClick={() => save(0)}>
                                Odmítnout
                            </Button>
                        </Col>
                        <Col sm={4}>
                            <Button variant="outline-secondary" className="mb-1 d-block" onClick={settingsVisible ? () => save(2) : () => toggleSettingsVisible(true)}>
                                {settingsVisible ? "Uložit předvolby" : "Zobrazit předvolby"}
                            </Button>
                        </Col>
                    </Row>
                    {
                        props.gdprDocument ? <div className="text-center mt-2"><Link to={`/dokument/${props.gdprDocument.identifier}`}>{props.gdprDocument.name}</Link></div> : null
                    }
                </div>
            </div>
        </div>
    )
}