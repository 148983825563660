import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import Home from './components/pages/Home';
import Advice from './components/pages/Servicedetail';
import Error from './components/pages/Error';
import Contact from './components/pages/Contact';
import Document from './components/pages/Document';
import axios from "axios"

axios.defaults.headers.common['Optional'] = "BvcDfWvpq84rdB5AssAmQPd9JZpJKvmMJ42aubLFku75VL9"
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default () => {
  return (
        <Router>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/online-radce' component={Advice}/>
            <Route exact path='/404' component={Error}/>
            <Route exact path='/kontakt' component={Contact}/>
            <Route exact path='/dokument/:identifier' component={Document}/>
            <Redirect to="/404"/>
          </Switch>
        </Router>
  )
}