export default [
    {
        id: 1,
        linkText: 'Informace',
        link: '#_informace'
    }, {
        id: 2,
        linkText: 'Partneři',
        link: '#_partneri'
    }, {
        id: 3,
        linkText: 'Reference',
        link: '#_reference'
    }, {
        id: 4,
        linkText: 'Online rádce',
        link: 'online-radce'
    }, {
        id: 5,
        linkText: 'Kontakt',
        link: 'kontakt'
    }
]