import React, {useEffect} from 'react'
import navigation from '../../constants/navigation'
import {Link, useLocation} from "react-router-dom"
import scrollTo from "../../utils/scrollTo"

export default (props) => {
    const router = useLocation()
    function scroll(e, href) {
        e.preventDefault()
        scrollTo(href.replace('#_', ''))
        if(props.isLanding && !props.isFooter){
            props.onMenuItemClick()
        }
    }

    const {hash} = router
    useEffect(() => {
        if (hash) {
            setTimeout(() => {
                scrollTo(hash.replace('#_', ''))
            }, 500)
        }
    }, [])

    return(
        <nav>
            <ul className={`custom ${props.isFooter && 'links'}`}>
                {navigation.length > 0 ? navigation.map((item, i) => (
                    <li key={i}
                        className={`menu-item ${!props.isLanding && router.pathname === ('/'+item.link) && 'active'}`}>
                        {
                            props.isLanding && item.link.includes("#") ? (
                                <a href={item.link}
                                   className="text-custom-white"
                                   onClick={e => scroll(e, item.link)}>{item.linkText}</a>
                            ) : (
                                <Link to={'/' + item.link}
                                      className="text-custom-white"> {item.linkText} </Link>
                            )
                        }
                    </li>
                )) : null}
            </ul>
        </nav>
    )
}