import React  from 'react';
import Header from '../layouts/Header';
import Breadcrumb from '../layouts/Breadcrumb';
import Servicedetailcontent from '../sections/service-detail/Servicedetail';
import Footer from '../layouts/Footer';

export default () => {
        return (
            <div>
                <Header/>
                <Breadcrumb heading="Online rádce"/>
                <Servicedetailcontent/>
                <Footer/>
            </div>
        )
}


