import React  from 'react'
import contacts from "../../../constants/contacts"


export default () => {
        return (
            <section className="section-padding bg-gray contact-us">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-4 mb-md-80">
                            <div className="contact-form full-height align-self-center bx-wrapper bg-custom-white">
                                <h4 className="text-custom-black fw-600">Informace</h4>
                                <p className="text-light-white">
                                    <span className="text-gold">
                                            <strong>ASTORIE a.s. – centrála společnosti </strong>
                                        </span>
                                </p>
                                <p className="text-light-white"><strong>ičo:</strong> 48293776</p>
                                <p className="text-light-white"><strong>dič:</strong> CZ48293776</p>
                                <p className="text-light-white"><strong>datová schránka:</strong> az6cw5g</p>
                                <p className="text-light-white"><strong>číslo účtu:</strong> 1004534389/0800</p>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-8">
                            <iframe className="full-width full-height" title="map" src={contacts.mapEmbed} />
                        </div>
                    </div>
                </div>
            </section>
        )
}