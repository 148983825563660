import React, {useState} from 'react'
import contacts from "../../constants/contacts"
import validator from 'validator'
import axios from 'axios'
import {detect} from 'detect-browser'
import {Carousel} from "react-bootstrap"


const defaultForm = {
    name: '',
    email: '',
    message: '',
}

export default () => {
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(0) //0 = default, 1 = send, 2 = err
    const [form, setForm] = useState({...defaultForm})
    const [errors, setErrors] = useState({})

    function onChangeForm(name, value) {
        const f = {...form}
        const e = {...errors}
        f[name] = value.substr(0, 1000)
        delete e[name]
        setForm(f)
        setErrors(e)
    }

    function validate() {
        const e = {}
        if (!form.name) {
            e.name = "Vyplňte prosím vaše jméno."
        }

        if (form.email && !validator.isEmail(form.email)) {
            e.email = "Neplatný formát emailové adresy."
        }

        if (!form.email) {
            e.email = "Vyplňte prosím emailovou adresu."
        }

        if (!form.message) {
            e.message = "Vyplňte prosím zprávu."
        }

        return {
            isValid: Object.keys(e).length === 0,
            errors: e
        }
    }

    function onSubmit(e) {
        e.preventDefault()
        setErrors({})
        const {isValid, errors} = validate()

        if (isValid) {
            setLoading(true)

            axios({
                method: 'POST',
                url: '/api/contact',
                headers: {
                    'Optional': 's8xz4TaabHKXQJCCmrc4rqY4QJMr3UZFTcM7pFZ4aPJUNBYQ',
                    'Content-Type': 'application/json',
                    'Browser': btoa(JSON.stringify(detect()))
                },
                data: {...form}
            }).then(res => {
                setStatus(1)
            }).catch(err => {
                setStatus(2)
            }).then(() => {
                setLoading(false)
            })
        } else {
            setErrors(errors)
        }
    }

    function resetForm() {
        setStatus(0)
        setForm({...defaultForm})
    }

    return (
        <section className="book-appointment parallax section-padding" id="book-appointment">
            <div className="overlay overlay-bg-black"/>
            <div className="pattern"/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="appointment-sec">
                            <div className="row no-gutters">
                                <div className="col-lg-5">
                                    <div className="padding-40 full-height bg-white">
                                        <div className="align-self-center">
                                            <div className="section-heading">
                                                <h3 className="text-custom-blue fw-600 ">Kontaktujte nás</h3>
                                            </div>
                                            <p className="text-dark">Potřebujete s nečím poradit</p>
                                            <h6 className="fs-20 mt-4 text-dark">Na telefonu jsme pro Vás každý všední den od 8
                                                do 15 hodin.</h6>
                                            <a href={`tel:${contacts.phone}`} className="btn-first btn-submit-fill  full-width"><i
                                                className="fas fa-phone mr-2 fs-16"/><span
                                                className="fs-16">{contacts.phone}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="right-side full-height bg-border">
                                        <div className="section-heading">
                                            <h3 className="text-white fw-600">Kontaktní formulář</h3>
                                        </div>
                                        <Carousel activeIndex={status} controls={false} interval={null} indicators={false} fade={true}>
                                            <Carousel.Item>
                                                <form className="form-style-2 form-style-3" onSubmit={loading ? null : (e) => onSubmit(e)}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className={`form-group ${errors.name && 'has-error'}`} title={errors.name}>
                                                                <div className="input-group"><span
                                                                    className="input-group-preappend"><i
                                                                    className="fas fa-user"/></span>
                                                                    <input type="text" name="name" className="form-control"
                                                                           placeholder="Jméno"
                                                                           value={form.name}
                                                                           onChange={(e) => onChangeForm("name", e.target.value)}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className={`form-group ${errors.email && 'has-error'}`} title={errors.email}>
                                                                <div className="input-group"><span
                                                                    className="input-group-preappend"><i
                                                                    className="fas fa-envelope"/></span>
                                                                    <input type="text" name="email" className="form-control"
                                                                           placeholder="Emailová adresa"
                                                                           value={form.email}
                                                                           onChange={(e) => onChangeForm("email", e.target.value)}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className={`form-group ${errors.message && 'has-error'}`} title={errors.message}>
                                                                <div className="input-group"><span
                                                                    className="input-group-preappend"><i
                                                                    className="fas fa-phone-alt"/></span>
                                                                    <textarea type="text" name="message"
                                                                              className="form-control"
                                                                              placeholder="Zpráva"
                                                                              value={form.message}
                                                                              onChange={(e) => onChangeForm("message", e.target.value)}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <button type="submit"
                                                                    className="btn-first btn-submit full-width" disabled={loading}>
                                                                {loading ? "Odesílám..." : "Odeslat"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div className="message success">
                                                    <p>Zpráva byla úspěšně odeslána</p>
                                                    <button type="button" className="btn-first btn-submit full-width"  onClick={() => resetForm()}>Zavřít</button>
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div className="message error">
                                                    <p>Odeslání zprávy se nezdařilo. Zkuste to prosím později.</p>
                                                    <button type="button" className="btn-first btn-submit full-width"  onClick={() => setStatus(0)}>Opakovat</button>
                                                </div>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}